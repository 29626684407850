<template>
  <BaseArtist
    :artist="artist"
    :artist-banners="artistBanners"
    :events="events"
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
    :event-list-attention-verified="eventListAttentionVerified"
    @term-service-click="handleTermServiceClick"
    @to-top="toTop"
  />
</template>

<script>
import {mapState} from 'vuex'
import {CONFIRMATION_NAMES, CONFIRMATION_STATUS} from "@/constants/ArtistConfirmation";
import EventService from '@services/EventService'
import ArtistBannerService from '@services/ArtistBannerService'
import pageDisplayControlMixin from "@/helpers/pageDisplayControlMixin";

export default {
  components: {
    BaseArtist: () => import('./base/Artist'),
  },
  mixins: [ pageDisplayControlMixin ],
  metaInfo() {
    return {
      title: this.titleTag,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from.fullPath
    })
  },
  data() {
    return {
      artistId: '',
      artist: {},
      events: [],
      artistBanners: [],
      partner_id: '',
      from: '',
      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',
    }
  },
  created() {
    this.renderPage()
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState([
      'isMobile',
      'MessageWhenHidden',
      'artistConfirmation'
    ]),
    titleTag() {
      return this.artist ? this.artist.name : 'Bromide';
    },
    eventListAttentionVerified() {
      return this.artistConfirmation &&
        (this.artistConfirmation[CONFIRMATION_NAMES.EVENT_LIST_ATTENTION] === CONFIRMATION_STATUS.CONFIRMED ||
        this.artistConfirmation[CONFIRMATION_NAMES.EVENT_LIST_ATTENTION] === CONFIRMATION_STATUS.NOT_DEMAND)
    },
  },
  methods: {
    toTop() {
      this.$router.push({name: 'top'}).catch(() => {
        this.$router.go()
      })
    },
    handleTermServiceClick() {
      // showTermAgreementの値を書き換えるアクション
      this.$store.commit('SHOW_EVENT_LIST_ATTENTION')
    },
    async renderPage() {
      localStorage.setItem('header_logo_image_url', '')

      const currentArtists = this.$store.state.current_artist

      localStorage.setItem('header_logo_image_url', currentArtists.header_logo_image_url)

      this.$store.dispatch('setIsMsgWhenHidden', currentArtists.is_msg_when_hidden)

      if (currentArtists.is_msg_when_hidden) {

        this.isMsgHidden = true
        this.msgTitle = currentArtists.msg_when_hidden_title
        this.msgSubTitle = currentArtists.msg_when_hidden_date
        this.msgContent = currentArtists.msg_when_hidden
        this.$store.commit('SHOW_LAYOUT')

      } else {

        this.artistId = currentArtists.id
        this.artist = currentArtists
        this.partner_id = currentArtists.partner_id
        this.$store.commit('SHOW_LAYOUT')

        EventService.getEventByArtistId(this.artistId).then((res) => {
          if (res.data) {
            this.events = res.data
          }
        })

        ArtistBannerService.getArtistBannerByArtistId(this.artistId).then((res) => {
          this.artistBanners = res.data
        })
      }

    },
  },
}
</script>
