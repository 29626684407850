import BaseService from "./BaseService";

class AuthService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  getArtistBannerByArtistId(artist_id) {
    let url = `get-by-artist-id/${artist_id}`
    return this.performRequest(BaseService.METHOD_GET, url);
  }
}

export default new AuthService('artist-banners');